<template>
	<div>
		<common-table
				:itemScope="['lsell', 'storePrice']"
				:headers="headers"
				:listData="listData"
				@changePage="changePage"
				:loading="loading"
				:btnAction="true"
				@search="search"
				@remove="remove"
				@save="save"
				@edit="edit"
				ref="ct"
				:rightConfig="rightConfig"
				:leftConfig="leftConfig"
				pageTitle="产品小类"
				:listDeploy="listDeploy"
				@beforeOpenAddDialog="beforeOpenAddDialog"
		>
			<template v-slot:lsell="{ item }">
				<w-switches
						inset
						label=""
						color="var(--v-primary-base)"
						@change="updateStatus(item.sqlid)"
						v-model="item.lsell" hide-details dense/>
			</template>

			<template v-slot:storePrice="{ item }">
				<v-icon
						class="iconfont ml-2"
						color="primary"
						size="24"
						@click="storePrice(item.code)">
					iconfont icon-chakan-fill
				</v-icon>
			</template>
		</common-table>
		<template v-if="storePriceDialog">
			<form-menu
					:center="true"
					height="250px"
					width="392px"
					title="库存"
					:center_btn="true"
					:listDeploy="storePriceListDeploy"
					leftbtn_text="取消"
					:cancel_reset="true"
					@resetForm="storePriceDialog = false"
					:ridDivision="true"
					@validateSave="validateSave"
					:dialog.sync="storePriceDialog"/>
		</template>
	</div>
</template>

<script>
import commonCurd from "@/mixin/commonCurd";
import api from '@/api/productManage/product-children.js';
import formMenu from "@/components/form-menu/form-menu.vue";
import commonData from "@/api/common-data.js";

export default {
	mixins: [commonCurd],
	components: {formMenu},
	data() {
		return {
			api,
			// 排序字段，不填默认sqlid
			sortname: 'seq',
			typeCode: '',
			storePriceDialog: false,
			rightConfig: [
				{label: '新建', icon: 'zengjia', event: 'add'}
			],
			leftConfig: [{type: 'input', label: '输入产品小类代码/名称', property: 'searchkey', width: '180px'}],
			headers: [
				{text: '产品小类代码', value: 'code'},
				{text: '产品小类名称', value: 'description'},
				// {text: '实名制', value: 'lreal'},
				{text: '月销笔数', value: 'pinnum'},
				{text: '排序号', value: 'seq'},
				this.$route.query.type === '1' ? {text: '库存', value: 'storePrice'} : '',
				{text: '预订状态', value: 'lsell'},
				{text: '操作', value: 'actions', width: 120}
			],
			storePriceListDeploy: {
				row: [
					{
						cols: 12,
						subassembly: "VTextField",
						property: 'spuitem',
						connector: {
							style: {
								display: 'none'
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'avl',
						connector: {
							props: {
								required: true,
								label: "剩余库存"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'pickup',
						connector: {
							props: {
								disabled: true,
								label: "已售数量"
							}
						}
					}
				]
			},
			listDeploy: {
				row: [
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'code',
						hasEditDisabledProperty: true,
						connector: {
							props: {
								required: true,
								label: "产品小类代码"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'description',
						connector: {
							props: {
								required: true,
								label: "产品小类名称"
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'rulecode',
						connector: {
							props: {
								required: true,
								label: "业务规则",
								items: []
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'showprice',
						connector: {
							props: {
								label: "产品售价"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'pinnum',
						connector: {
							props: {
								label: "月销笔数"
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'seq',
						connector: {
							props: {
								label: "排序号码",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "VTextField",
						property: 'expireday',
						connector: {
							props: {
								label: "有效天数",
								type: 'number'
							}
						}
					},
					{
						cols: 6,
						subassembly: "WAutocomplete",
						property: 'usetips',
						connector: {
							props: {
								required: false,
								label: "核销提示",
								items: [],
								chips: true
							},
							style: {
								display: this.$route.query.group === 'YYJJ0002' ? '' : 'none'
							}
						}
					},
					{
						cols: 3,
						subassembly: "WSwitches",
						property: 'lsell',
						disabledDeleteDefault: true,
						connector: {
							props: {
								required: true,
								label: "预订状态",
								inset: true
							}
						},
						default: true
					},
					// {
					//   cols: 3,
					//   subassembly: "WSwitches",
					//   property: 'lavl',
					//   connector: {
					//     props: {
					//       required: true,
					//       label: "调整库存",
					//       inset: true
					//     }
					//   }
					// },
					{
						cols: 12,
						subassembly: 'WFileinput',
						property: 'audiourl',
						connector: {
							props: {
								uniline: true,
								hint: '音频建议：大小不能超过20MB，格式仅限于flac、mp3、wav',
								label: '上传音频',
								title: '音频',
								fileType: 'commodity',
								icon: 'icon-shipin',
								accept: [
									{range: 'audio/mpeg', suffixs: ['mp3']},
									{range: 'audio/flac', suffixs: ['flac']},
									{range: 'audio/wav', suffixs: ['wav']}
								],
								size: 20
							},
							style: {
								display: this.$route.query.type === '2' ? '' : 'none'
							}
						}
					},
					{
						cols: 12,
						subassembly: 'MaterialFile',
						property: 'mlistpic',
						connector: {
							props: {
								required: true,
								showAddIcon: false,
								hint: '图片建议：宽高比例1:1，大小不能超过2MB，格式仅限于jpg、png、jpeg、webp',
								label: '上传图片',
								title: '应用端轮播图',
								fileType: 'commodity'
							}
						}
					},
					// {
					// 	cols: 12,
					// 	subassembly: 'MaterialFile',
					// 	property: 'listpic',
					// 	connector: {
					// 		props: {
					// 			required: false,
					// 			showAddIcon: false,
					// 			hint: '大小不能超过2MB，格式仅限于jpg、png',
					// 			label: '上传图片',
					// 			title: '电脑端列表图(320*200)',
					// 			fileType: 'commodity'
					// 		}
					// 	}
					// }
				]
			}
		}
	},
	methods: {
		// 库存&价格
		storePrice(item) {
			this.axios.post('/api/product/load_resources_price', {searchkey: item}).then(res => {
				this.storePriceListDeploy.row[0].default = res.data.spuitem;
				this.storePriceListDeploy.row[1].default = res.data.avl;
				this.storePriceListDeploy.row[2].default = res.data.pickup;
				this.storePriceDialog = true;
			})
		},
		createdExtend() {
			this.fixedParams.group = this.$route.query.code;
		},
		beforeSave(form) {
			form.groupid = this.$route.query.code;
		},
		beforeOpenAddDialog() {
			this.axios.post(
					commonData.select,
					commonData.getSelectList({keyname: "rules,usetips"})
			).then((res) => {
						if (res?.data) {
							this.listDeploy.row[2].connector.props.items = res.data[0].values
							if (this.$route.query.group === 'YYJJ0002') {
								this.listDeploy.row[7].connector.props.items = res.data[1].values
							}
						}
					}
			);
		},
		validateSave(item, callback) {
			if (item.done) {
				this.axios.post('/api/product/save_resources_price', item.form).then(r => {
					if (r.code === this.staticVal.Code.Success) {
						this.snackbar.success("保存成功");
						callback();
						this.list();
					} else {
						this.$refs.ct.$refs.formRef.load = false;
					}
				});
			}
		}
	}
}
</script>

<style scoped lang="scss">

</style>
